<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block"><i class="uil uil-shield-check display-6 text-primary"></i></div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                  <h4 class="fw-medium font-size-20 text-dark mb-0"> {{ title }}</h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Authorization Approval Settings</small>
              </div>

              <!-- <div class="ms-auto">
                <button class="btn btn-outline-info fw-medium me-2">
                  <i class="uil uil-plus font-size-16 me-1"></i> Button
                </button>
              </div> -->
            </div>
          </div>
          
          <div class="card-body">
            <div class="text-center">
              <div
                class="spinner-border text-secondary my-5"
                v-if="loading"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-for="(value, index) in authorization" :key="index">
              <h4 class="text-capitalize text-info font-size-18 mb-3">{{ value.type.replaceAll('_',' ') }}</h4>
             
                <div class="table-responsive text-nowrap font-size-14 mb-3">
                  <table class="table mb-3 mb-0 align-middle min-width-760" >
                    <thead class="text-uppercase bg-info text-white">
                      <tr>
                        <th>Contract Awarder</th>
                        <th>Payment Mode</th>
                        <th>Facility Exposure</th>
                        <th>Approving Authorities</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!value.contract.length && !loading">
                        <td colspan="4" height="200" class="text-center text-muted">
                          <i class="uil uil-shield-check me-1"></i> No Data Available
                        </td>
                      </tr>
                      <tr v-if="loading">
                        <td colspan="4" class="text-center text-muted" height="300">
                          <div
                            class="spinner-border text-secondary my-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                          <br />
                          <div>Loading...</div>
                        </td>
                      </tr>
                      <tr v-for="(v, i) in value.contract" :key="i">
                        <td>
                         <div v-for="(contract, ii) in v.contract_awarder_type" :key="ii">
                          {{ contract.name }}
                          </div>
                        </td>
                        <td>
                          <!-- <select class="form-select mb-1" v-model="v.payment_mode">
                            <option v-for="(option, optionIndex) in payment_mode" :key="optionIndex" :value="option">{{option.name}}</option>
                          </select> -->
                          {{ v.payment_mode.name }}
                        </td>
                        <td>
                         <span class="text-capitalize" v-if="v.facility_exposure_type=='open'">{{ v.facility_exposure_type }}</span> 
                         <span class="text-capitalize" v-if="v.facility_exposure_type=='above'">Above RM </span> 
                         <span class="text-capitalize" v-if="v.facility_exposure_type=='up_to'">Up to RM </span> 
                         <span class="text-capitalize" v-if="parseInt(v.facility_exposure)>0">{{ convertCurrencyFormat(v.facility_exposure,false) }}</span> 
                         <!-- {{ v.facility_exposure }} -->
                        </td>
                        <td>
                          <select class="form-select mb-1 text-dark bg-white" v-model="v.approving_authorities_user1">
                            <option v-for="(option, optionIndex) in users" :key="optionIndex" :value="option">{{option.name}}</option>
                          </select>
                          <select class="form-select text-dark bg-white" v-model="v.approving_authorities_user2 ">
                            <option v-for="(option, optionIndex) in users" :key="optionIndex" :value="option">{{option.name}}</option>
                          </select>
                          <!-- <div v-if="v.approving_authorities_user1">{{ v.approving_authorities_user1.name  }} </div>
                          <div v-if="v.approving_authorities_user2">{{ v.approving_authorities_user2.name }} </div> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
            <div class="text-lg-end">
              <button class="btn btn-primary me-2" @click="submit" :disabled="buttonLoading || loading" v-if="create_modify_permission">
                <i class="uil uil-save"></i>
                <span v-if="buttonLoading"> Saving...</span>
                <span v-else> Save &amp; Update </span>
                <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'animate/loading_blue.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import {required } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== 'YOUR_API_ID';
}
export const equal2B = (value) => {
  return value !== 'YOUR_API_HASH';
}

/**
 * Starter page
 */
 const PageTitle = "Approving Authority"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      account_type:'',
      showLottie:false,
      title: '',
      users:[],
     contract_awarder:[
        {
          "name": "Federal government",
          "value": "federal_goverment"
        },
        {
          "name": "Non-federal government",
          "value": "non_federal_goverment"
        }
      ],
      payment_mode:[
        {
          name: 'ePerolehan',
          value: 'eP'
        }, 
        {
          name: 'Non-ePerolehan', 
          value: 'Non-eP'
        }
      ],
      authorization:[
      ],
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:false,
      create_modify_permission:false
    };
  },
  validations: {
    formData: {
      apiID: {
        required,
        equal2A
      },
      apiHash: {
        required,
        equal2B
      },
    }
  },
  middleware: "authentication",
  async mounted(){
    await this.reload()
    this.title = PageTitle
    this.items[1].text = PageTitle
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    await this.getUser();
    
  }, 
  created(){
   
  },
  methods:{
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc
          .convertCurrencyFormat(value)
          .replaceAll(".00", "");
      }
    },
    async getUser(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "9999");
        axios({
            method: "post",
            url:  appConfig.APIHost + 'controller/user/getAllUserList',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData);
              resData.data.forEach(element => {
                if (element.signing_authority=="available"){
                  this.users.push({
                    account_db_code: element.account_db_code,
                    username: element.username,
                    name: element.name,
                    full_name: element.full_name,
                    nric_no: element.nric_no,
                    signature: element.signature,
                    position: element.position,
                    email: element.email,
                    status: element.status
                  })
                }
              })
              this.getData()
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
              this.loading = false;
              this.$Progress.finish();
            }
            else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })

              this.loading = false;
              this.$Progress.finish();
            }
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
            method: "post",
            url: appConfig.APIHost + 'controller/authority/getAuthoritySetting',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.authorization= resData.data
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    submit(){
        this.$Progress.start();
        this.buttonLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("authorization", JSON.stringify(this.authorization));
        axios({
            method: "post",
            url: appConfig.APIHost + 'controller/authority/updateAuthorityInfo',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Authorities Update Successful',
                html: 'Authorization Approval Settings Updated',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.getData();
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.buttonLoading = false;
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.buttonLoading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
    },
    
    reset() {
        this.submitted = true
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[1] = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("phone",  this.accessPhone);
            axios({
                method: "post",
                url: appConfig.DemoAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response)=>{
                var resData = response.data;
                if (resData.status == 200) {
                  console.log(resData)
                  Swal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    html: `API ID and API Hash have been successfully deleted and reset!`,
                    confirmButtonColor: '#FA6541',
                    confirmButtonText: this.$t('siteLang.Done')
                  })
                  const updateData = {
                    "apiID": 'YOUR_API_ID',
                    "apiHash": 'YOUR_API_HASH',
                 }
                 this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                 this.reload()
                   
                } 
                else if (resData.status == 440){
                  Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                    localStorage.clear();
                    this.$router.push({
                        path: "/login",
                  });
                }
                else {
                    Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                }
                this.loading = false;
                this.submitted = false
                this.buttonLoading[1] = false
                this.$Progress.finish();
            })
            .catch((error)=> {
                this.buttonLoading[1] = false
                this.loading = false;
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name==='approving_authority')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "approving_authority" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>